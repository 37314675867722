export default function AnimatedImage({src, className, alt, isFooterSceneAnimating}) {
  function hoverAnimate(e) {
    if (!isFooterSceneAnimating) {
      e.target.src = `https://bin.complex.com/websites/hot-ones-jr/img/${src}.png`
    }
  }

  function hoverPause(e) {
    if (!isFooterSceneAnimating) {
      e.target.src = `https://bin.complex.com/websites/hot-ones-jr/img/${src}-static.png`
    }
  }

  return (
    <img className={className} onMouseEnter={hoverAnimate} onMouseLeave={hoverPause} src={`https://bin.complex.com/websites/hot-ones-jr/img/${src}-static.png`} alt={alt} />
  )  
}