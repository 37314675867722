import { slide as Menu } from "react-burger-menu";
import NavLinks from './navLinks'
import Footer from './footer'
import NavFlavors from './navFlavors'
export default function SideBar(props) {
  return (
    <Menu {...props}>
      <div className="nav-content--top">
        <NavLinks {...props}></NavLinks>
      </div>
      <br></br>
      <div className="nav-content--bottom">
        <NavFlavors {...props}></NavFlavors>
      </div>
      <Footer greenPage yellowPage mobileMenu></Footer>
    </Menu>
  );
};
