import SideBar from './sideBar'
import NavLinks from './navLinks'
import NavFlavors from './navFlavors'
import Link from 'next/link'

import logoHome from '../public/static/images/logo-home.png';
import logoGreen from '../public/static/images/logo-the-green.png';
import logoYellow from '../public/static/images/logo-the-yellow.png';
import logoRed from '../public/static/images/logo-the-red.png';
import sunImg from '../public/static/images/sun.png';
import navHamburger from '../public/static/images/nav-hamburger.png';
import navSidebar from '../public/static/images/nav-sidebar-x.svg';

export default function Navbar(props) {

    function getLogoSrc() {
        let logo = logoHome;
        if (props.greenPage) {
            logo = logoGreen;
        } else if (props.yellowPage) {
            logo = logoYellow;
        } else if (props.redPage) {
            logo = logoRed;
        }
        return logo;
    }

    return (
        <div className="nav">
            <img className="sun" src={sunImg} alt="happy sun" />
            <div className="nav-content nav-content--left">
                <NavLinks {...props}></NavLinks>
            </div>
            <Link href="/" passHref>
                <img src={getLogoSrc()} className="logo" alt="hot ones jr" />
            </Link>
            <SideBar
                width='100%'
                height='100%'
                right
                customBurgerIcon={<img src={navHamburger} alt="hamburger icon" />}
                customCrossIcon={<img src={navSidebar} alt="close icon" />}
                noOverlay
                disableAutoFocus
                {...props}
            />
            <div className="nav-content nav-content--right">
                <NavFlavors {...props}></NavFlavors>
            </div>
        </div>
    )
}
