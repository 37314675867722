import Link from 'next/link'
import clsx from 'clsx'
export default function NavLinks(props) {
    return (
        <>
            <Link href="/" ><a className={clsx('nav-item', props.homePage && 'nav-active')}>Home</a></Link>
            <Link href="/play-word-search-game" ><a className={clsx('nav-item', props.puzzlePage && 'nav-active')}>Play the Game</a></Link>
            <Link href="/take-survey" ><a className={clsx('nav-item', props.surveyPage && 'nav-active')}>Take a Survey</a></Link>
        </>
    )
}