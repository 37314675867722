import styles from './Footer.module.scss'
import AnimatedImage from './animatedImage'
import { useInView } from 'react-intersection-observer'

import pepperImg from '../public/static/images/pepper.png';
import viking from '../public/static/images/viking.svg';
import facebook from '../public/static/images/facebook.svg';
import twitter from '../public/static/images/twitter.svg';
import instagram from '../public/static/images/instagram.svg';
import youtube from '../public/static/images/youtube.svg';
import redFooterCarrot from '../public/static/images/red-footer-carrot.png';
import redFooterPepper from '../public/static/images/red-footer-pepper.png';

export default function Footer({ homePage, greenPage, yellowPage, redPage, surveyPage }) {
    const { ref } = useInView({
        /* Optional options */
        threshold: 0.75,
    });

    function handlePrivacyOpen(e) {
        e.preventDefault();
        if (OneTrust) {
            OneTrust.ToggleInfoDisplay()
        }
    }

    return (
        <div ref={ref} className={`${styles.container} ${(homePage || greenPage || yellowPage || redPage) && styles['home-container']}`}>
            {
                (homePage || greenPage || yellowPage || redPage) &&
                <div className={styles["social-wrap"]}>
                    <a className={styles['fwf-link']} href="https://firstwefeast.com/" target="_blank" rel="noopener noreferrer">
                        <img src={viking} className={`${styles.icon} ${styles.viking}`} alt="viking icon" />
                        <span className={styles["fwf-text"]}>FirstWeFeast.com</span>
                    </a>
                    <a href="https://www.facebook.com/FirstWeFeast" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} className={`${styles.icon} ${styles.facebook}`} alt="facebook icon" />
                    </a>
                    <a href="https://twitter.com/firstwefeast" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} className={`${styles.icon} ${styles.twitter}`} alt="twitter icon" />
                    </a>
                    <a href="https://www.instagram.com/firstwefeast/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} className={`${styles.icon} ${styles.instagram}`} alt="instagram icon" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCPD_bxCRGpmmeQcbe2kpPaA" target="_blank" rel="noopener noreferrer">
                        <img src={youtube} className={`${styles.icon} ${styles.youtube}`} alt="youtube icon" />
                    </a>
                </div>
            }
            {
                greenPage &&
                <AnimatedImage className={`${styles.apple}`} src="apple" alt="apple on a unicycle" />
            }
            {
                greenPage &&
                <AnimatedImage className={`${styles.pepper}`} src="pepper" alt="pepper on a scooter" />
            }
            {
                yellowPage &&
                <AnimatedImage className={`${styles.mango}`} src="mango" alt="mango" />
            }
            {
                yellowPage &&
                <AnimatedImage className={`${styles['pepper-orange']}`} src="pepper-orange" alt="pepper-orange with sunglasses" />
            }
            {
                redPage &&
                <img className={styles.carrot} src={redFooterCarrot} alt="Carrot" />
            }
            {
                redPage &&
                <img className={styles['pepper-red']} src={redFooterPepper} alt="Skate Peper" />
            }
            <div className={styles["legal-wrap"]}>
                <a href="https://firstwefeast.com/terms" className={styles.legal} target="_blank" rel="noopener noreferrer">Terms of use</a>
                <a href="https://firstwefeast.com/privacy" className={styles.legal} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <a href="#cookie-settings" id="ot-sdk-btn" className={`${styles.legal} ot-sdk-show-settings`} onClick={handlePrivacyOpen}>Cookies Settings</a>
                <p className={styles["copyright"]}>© First We Feast. All rights Reserved.</p>
                {
                    surveyPage &&
                    <img className={`${styles.pepper} ${styles["pepper-survey"]}`} src={pepperImg} alt="pepper on a scooter" />
                }
            </div>
        </div>
    )
}
