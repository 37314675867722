import Link from 'next/link'

export default function NavFlavors(props) {
    return (
        <>  
            <p className='nav-flavor-title'><b>Flavors:</b></p>
            <Link href="/the-green-sauce" >
                <a className={`nav-item subpage nav-item-green ${props.greenPage && 'nav-active'}`}>
                    <span className='nav-flavor-dot nav-flavor-dot--green'></span>The Green
                </a>
            </Link>
            <Link href="/the-yellow-sauce" >
                <a className={`nav-item subpage nav-item-yellow ${props.yellowPage && 'nav-active'}`}>
                    <span className='nav-flavor-dot nav-flavor-dot--yellow'></span> The Yellow
                </a>
            </Link>
            <Link href="/the-red-sauce" >
                <a className={`nav-item subpage nav-item-red ${props.redPage && 'nav-active'}`}>
                    <span className='nav-flavor-dot nav-flavor-dot--red'></span> The Red
                </a>
            </Link>  
        </>
    )
}