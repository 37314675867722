export default function Bubbles() {
    function listItems(num) {
        let arr = [];
        for (let i = 1; i <= num; i++) {
            arr.push(<li key={i}></li>)
        }
        return arr;
    }

    return (
        <ul className="bubbles">
            {listItems(40)}
        </ul>
    )
}