import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import Navbar from './navbar'
import Footer from './footer'
import { useEffect } from 'react';

const siteTitle = "Hot Ones Jr. 'The Green' and 'The Yellow': World's Friendliest Hot Sauce For Kids";
const siteDescription = "Make every meal an adventure with Hot Ones 'The Green' and 'The Yellow', the world's friendliest hot sauce for kids. Included is a puzzle game for children of all ages to play.";
import shareImageFacebook from '../public/static/images/sharefbv2.jpg';
import shareImageTwitter from '../public/static/images/sharev2.jpg';

export default function Layout({ origin, pageTitle, pageDescription, homePage, greenPage, yellowPage, redPage, surveyPage, puzzlePage, children }) {
  const router = useRouter();
  const basePath = router.basePath;
  const baseUrl = `${origin}${basePath}`;

  const title = pageTitle || siteTitle;
  const description = pageDescription || siteDescription;

  useEffect(() => {
    const bubblesWrap = document.querySelector('.bubbles');
    if (bubblesWrap) {
      bubblesWrap.classList.remove('yellow');
      bubblesWrap.classList.remove('green');
      bubblesWrap.classList.remove('red');

      if (greenPage) {
        bubblesWrap.classList.add('green');
      } else if (yellowPage) {
        bubblesWrap.classList.add('yellow');
      } else if (redPage) {
        bubblesWrap.classList.add('red');
      }
    }

  }, [greenPage, yellowPage, redPage])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="apple-touch-icon.png" />

        {/* Facebook Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={shareImageFacebook} />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Complex.com" />

        {/* Twitter Tags */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={shareImageTwitter} />
        <meta name="twitter:url" content={baseUrl} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* CHANGE IF YOU HAVE A "VANITY" URL, e.g. complex.com/brand */}
        {/* <link rel="canonical" href="https://www.hotonesjr.com" /> */}

        {/* Favicon */}
        <link rel="icon" href="https://www.buzzfeed.com/static-assets/_next/static/hot-ones-jr/favicon.ico" />
      </Head>

      {/* OneTrust Cookie Consent Notice */}
      <Script id="one-trust-sdk" src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="66378a21-0b50-4ec6-b927-14f10eeb02a8" />
      <Script id="one-trust-call">
        {`function OptanonWrapper() {}`}
      </Script>

      <div className="app">
        <Navbar homePage={homePage} greenPage={greenPage} yellowPage={yellowPage} redPage={redPage} surveyPage={surveyPage} puzzlePage={puzzlePage} ></Navbar>
        {children}
        <Footer homePage={homePage} greenPage={greenPage} yellowPage={yellowPage} redPage={redPage} surveyPage={surveyPage} puzzlePage={puzzlePage}></Footer>
      </div>
    </>
  )
}
